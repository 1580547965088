import * as React from "react";
import {GlobalStyles} from "@mui/material";
import {Outlet} from "react-router-dom";

import Axeptio from "./Common/domain/axeptio";

/**
 * L'app contient le thème commun et le router ("Outlet")
 * @constructor
 */
function App() {
    React.useEffect(() => {
        Axeptio.loadSdk();
    });

    return (
        <>
            <GlobalStyles styles={{p: {margin: 0, padding: 0}}}/>
            <Outlet/>
        </>
    );
}

export default App;
