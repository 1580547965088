import * as React from "react";
import {useParams} from "react-router-dom";
import {OriginalCreation} from "../../../api/interfaces/OriginalCreation";
import {getOriginalCreation} from "../../../api/consumer";
import {Dispatch, SetStateAction} from "react";
import {Article} from "../../../api/interfaces/Article";

export const useOriginalCreation = (): [OriginalCreation | undefined, Article | null, number, Dispatch<SetStateAction<number>>] => {
    const {originalCreationId, articleId} = useParams();
    const [originalCreation, setOriginalCreation] = React.useState<OriginalCreation | undefined>();
    const [currentArticleIndex, setCurrentArticleIndex] = React.useState<number>(0);
    const [currentArticle, setCurrentArticle] = React.useState<Article | null>(null);
    React.useEffect(() => {
        getOriginalCreation(originalCreationId)
            .then(response => {
                setOriginalCreation(response);
            });
    }, [originalCreationId]);

    React.useEffect(() => {
        if (undefined === originalCreation) {
            return; 
        }
        setCurrentArticleIndex(articleId ? originalCreation.articles.findIndex(item => item.id === articleId) : 0);
    }, [originalCreation, articleId]);

    React.useEffect(() => {
        if (undefined === originalCreation) {
            return; 
        }
        setCurrentArticle(articleId ? originalCreation.articles.find(item => item.id === articleId) : originalCreation.articles[0]);
    }, [currentArticleIndex, originalCreation, articleId]);
    return [originalCreation, currentArticle, currentArticleIndex, setCurrentArticleIndex];
};
