import * as React from "react";
import {Layout} from "../Common/components/Layout";
import {Box, Button, Container, Grid, Link, Typography} from "@mui/material";
import {Metas} from "../Common/components/Metas";
import {TitleGradient} from "../Common/components/TitleGradient";

type ContactCardProps = {
    cardTitle: string;
    titleColors: string[];
    cardSubtitle: string;
    linkLabel: string;
    linkHref: string;
    buttonColor: "primary" | "secondary";
    buttonLabel: string;
}

/**
 * Une carte de contact
 * @constructor
 */
function ContactCard({cardTitle, titleColors, cardSubtitle, linkLabel, linkHref, buttonColor, buttonLabel}: ContactCardProps) {
    return (
        <Box
            component={"section"}
            bgcolor={"#FFF"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            textAlign={"center"}
            boxSizing={"border-box"}
            p={4}
            borderRadius={2}
        >
            <TitleGradient
                variant={"h2"}
                component={"h1"}
                textAlign={"center"}
                color1={titleColors[0]}
                color2={titleColors[1]}
            >
                {cardTitle}
            </TitleGradient>
            <Typography variant={"h4"} component={"h2"} my={4}>
                {cardSubtitle}
            </Typography>
            <Box mb={4}>
                <Typography variant={"body1"} color={"text.secondary"}>{linkLabel}</Typography>
                <Link
                    sx={{
                        textDecoration: "underline",
                    }}
                    href={`mailto:${linkHref}`}
                    target={"_blank"}
                    rel={"noreferrer noopener"}
                    variant={"body1"}
                    color={"inherit"}
                >
                    {linkHref}
                </Link>
            </Box>
            <Button href={`mailto:${linkHref}`} color={buttonColor}>
                {buttonLabel}
            </Button>
        </Box>
    );
}

/**
 * La page de contact
 * @constructor
 */
export function Contact() {
    return (
        <>
            <Metas
                title={"Contact et partenariat"}
                description={"Vous êtes lecteur des contenus éditoriaux Datagora et vous avez des questions, remarques, propositions et souhaitez nous contacter ou vous produisez des études ou des rapports et aimeriez qu'on réfléchisse ensemble aux manières de les démocratiser et les rendre accessibles à un plus large public ?"}
            />
            <Layout>
                <Container maxWidth={"md"}>
                    <Box pt={4}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={6}>
                                <ContactCard
                                    cardTitle={"Contact"}
                                    titleColors={["#1D61FF", "#673AAB"]}
                                    cardSubtitle={"Vous êtes lecteur des contenus éditoriaux Datagora et vous avez des questions, remarques, propositions et souhaitez nous contacter ?"}
                                    linkLabel={"N'hésitez pas, c'est par ici :"}
                                    linkHref={"contact@datagora.fr"}
                                    buttonColor={"secondary"}
                                    buttonLabel={"Nous contacter"}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <ContactCard
                                    cardTitle={"Partenariat"}
                                    titleColors={["#673AAB", "#FF2E60"]}
                                    cardSubtitle={"Vous produisez des études ou des rapports et aimeriez qu'on réfléchisse ensemble aux manières de les démocratiser et les rendre accessibles à un plus large public ?"}
                                    linkLabel={"N'hésitez pas à nous écrire à :"}
                                    linkHref={"partenariat@datagora.fr"}
                                    buttonColor={"primary"}
                                    buttonLabel={"Devenir partenaire"}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Layout>
        </>
    );
}
