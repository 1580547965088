import * as React from "react";
import {Box, Grid, Typography} from "@mui/material";
import {OriginalCreationThumbnail} from "../../OriginalCreation/components/OriginalCreationThumbnail";
import {OriginalCreation} from "../../api/interfaces/OriginalCreation";

/**
 * Section "nos Séries exclusives" de la page d'accueil
 * @constructor
 */
export function HomeOriginalCreations({originalCreations}: {originalCreations: OriginalCreation[]}) {
    return (
        <section>
            <Box component={"header"} mb={2} mt={8}>
                <Typography variant={"h1"}>
                    Nos séries exclusives
                </Typography>
            </Box>
            <Grid container spacing={4}>
                {originalCreations.map((originalCreation) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={originalCreation.id}>
                        <OriginalCreationThumbnail originalCreation={originalCreation}/>
                    </Grid>
                ))} 
            </Grid>
        </section>
    );
}

