import * as React from "react";
import {Box, Typography, Link} from "@mui/material";
import {DatagoraImage} from "../../Common/components/DatagoraImage";
import {Article} from "../../api/interfaces/Article";
import {Link as RouterLink} from "react-router-dom";
import {uiHelper} from "../../Common/domain/helpers/uiHelper";

/**
 * La vignette d'une publication
 * @constructor
 */
export function CategoryThumbnail({article}: {article: Article}) {
    return (
        <>
            <Link
                component={RouterLink}
                to={uiHelper.getArticleLinkPath(article)}
                underline={"none"}
            >                          
                <Box>
                    <DatagoraImage
                        src={article.homepageImageUrl}
                        alt={article.title}
                    />
                    <Typography variant={"h4"} component={"h2"} sx={{marginTop: 2, marginBottom: 1}}>
                        {article.title}
                    </Typography>
                    <Typography variant={"caption"} component={"h3"}>
                        {article.subTitle}
                    </Typography>
                    <Box display={"flex"} flexDirection={"row"} pt={1}>
                        <Typography variant={"caption"} color={"primary"} mr={0.5}>
                            {article.origin}
                        </Typography>
                        <Typography variant={"caption"} color={"text.secondary"} mr={0.5}>
                            •
                        </Typography>
                        <Typography variant={"caption"} color={"text.secondary"} textTransform={"capitalize"}>
                            {new Date(article.publicationDate).toLocaleString("default", {month: "long", year: "numeric"})}
                        </Typography>
                    </Box>
                </Box>
            </Link>
        </>
    );
}
