import * as React from "react";
import {Box, Typography} from "@mui/material";
import {Article} from "../../api/interfaces/Article";
import ReactPlayer from "react-player/vimeo";
import {useEffect, useState} from "react";

/**
 * La player pour les vidéos Viméo
 * @constructor
 */
export function DatagoraVideoPlayer({article}: { article: Article }) {
    const [isVideoReady, setIsVideoReady] = useState(false);

    const [isCookieVimeoAccepted, setIsCookieVimeoAccepted] = React.useState(false);

    useEffect(() => setIsCookieVimeoAccepted(JSON.parse(localStorage.getItem("axeption_vimeo"))),[]);
    useEffect(() => setIsVideoReady(false),[article]);
    
    if (!isCookieVimeoAccepted) {
        return (
            <Box 
                bgcolor={"background.paper"}
                textAlign={"center"}
                boxSizing={"border-box"}
                p={3}
                borderRadius={2}
                sx={(theme) => ({
                    border: `1px solid ${theme.palette.divider}`
                })}
            >
                <Typography variant={"body1"} align={"center"}>
                    Il faut accepter le cookie Vimeo pour lire la vidéo
                </Typography>
            </Box>
        );
    }
    
    return (
        <>
            <Box
                height={0}
                position={"relative"}
                pt={"HORIZONTAL" === article.videoDisplay ? "56.25%" : "100%"}
            >
                <ReactPlayer
                    onReady={() => setIsVideoReady(true)}
                    url={article.video}
                    width="100%"
                    height="100%"
                    controls={true}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: isVideoReady ? "block" : "none"
                    }}
                />
            </Box>
        </>
    );
}
