import * as React from "react";
import {Header} from "./navigation/Header";
import {Footer} from "./Footer";
import {Box, Theme, ThemeProvider} from "@mui/material";
import {theme} from "../theme/theme";

/**
 * Le layout commun aux pages
 * @param children
 * @param themeOverride
 * @constructor
 */
export function Layout({children, themeOverride}: {children: React.ReactElement | React.ReactElement[]; themeOverride?: Theme | null}) {
    return (
        <ThemeProvider theme={themeOverride ?? theme}>
            <Box display={"flex"} flexDirection={"column"} minHeight={"100vh"}>
                <Header/>
                <Box
                    component={"main"}
                    pt={2}
                    pb={8}
                    sx={(theme) => ({
                        flexGrow: 1,
                        position: "relative",
                        backgroundColor: theme.palette.background.default
                    })}
                >
                    <Box sx={(theme) => ({paddingTop: {xs: theme.spacing(12), md: 0}})}>
                        {children}
                    </Box>
                </Box>
                <Footer/>
            </Box>
        </ThemeProvider>
    );
}
