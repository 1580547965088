import * as React from "react";
import {Typography, Box, Button} from "@mui/material";
import {uiHelper} from "../../Common/domain/helpers/uiHelper";
import {DatagoraSocialIcon} from "../../Common/components/DatagoraSocialIcon";

type ButtonSocialMediaFollowProps = {
    label: string;
    background: string;
    icon: string;
    href: string;
}

const SOCIAL_MEDIA_BUTTONS: ButtonSocialMediaFollowProps[] = [
    {
        label: "Instagram",
        background: "linear-gradient(90.58deg, #4F5BD5 0%, #962FBF 28%, #D62976 53%, #FA7E1E 81%, #FEDA75 100%)",
        icon: uiHelper.instagramIconPath,
        href: uiHelper.datagoraInstagramLink
    },
    {
        label: "LinkedIn",
        background: "#0077B5",
        icon: uiHelper.linkedinIconPath,
        href: uiHelper.datagoraLinkedInLink
    },
    {
        label: "Twitter",
        background: "#1DA1F2",
        icon: uiHelper.twitterIconPath,
        href: uiHelper.datagoraTwitterLink
    },
    {
        label: "TikTok",
        background: "#000",
        icon: uiHelper.tikTokIconPath,
        href: uiHelper.datagoraTikTokLink
    },
];

/**
 * Section dans un article qui permet de suivre Datagora sur ses réseaux sociaux
 * @constructor
 */
export function ArticleSocialMediaFollow() {
    return (
        <Box
            component={"section"}
            bgcolor={"background.paper"}
            textAlign={"center"}
            boxSizing={"border-box"}
            p={4}
            borderRadius={2}
            sx={(theme) => ({
                border: `1px solid ${theme.palette.divider}`
            })}
        >
            <Typography variant={"h4"}>
                Vous avez aimé cet article ?
            </Typography>
            <Typography variant={"h6"} component={"h5"} color={"text.secondary"} mt={1}>
                Suivez-nous sur les réseaux sociaux
            </Typography>

            <Box
                mt={4}
                display={"flex"}
                justifyContent={"center"}
                sx={{
                    flexDirection: {xs: "column", md: "row"}
                }}
            >
                {SOCIAL_MEDIA_BUTTONS.map((socialMediaButton) => (
                    <Button
                        key={socialMediaButton.label}
                        component={"a"}
                        href= {socialMediaButton.href}
                        target={"_blank"}
                        rel={"noreferrer noopener"}
                        sx={{
                            background: socialMediaButton.background,
                            "&:hover": {
                                background: socialMediaButton.background
                            },
                            "&:not(:last-child)": {
                                marginRight: {xs: 0, md : 2},
                                marginBottom: {xs: 2, md : 0},
                            },
                        }}
                    >
                        {socialMediaButton.label}
                        <Box ml={1}>
                            {<DatagoraSocialIcon path={socialMediaButton.icon} color={"#FFF"} size={"16px"}/>}
                        </Box>
                    </Button>
                ))}
            </Box>
        </Box>
    );
}
