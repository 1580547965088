import {Box, Skeleton} from "@mui/material";
import * as React from "react";
import {uiHelper} from "../../domain/helpers/uiHelper";

export function DatagoraSkeleton({width = "100%", height = "unset", aspectRatio}: {width?: string | number, height?: string | number, aspectRatio?: "1/1" | "16/9" | "12/5" | "4/3"}) {
    return (
        <Box sx={{
            display: "flex",
            height: height,
            width: width,
            paddingTop: uiHelper.getPaddingFromAspectRatio(aspectRatio),
            borderRadius: 2,
            overflow: "hidden",
            position: "relative"
        }}
        >
            <Skeleton variant={"rectangular"} height={"100%"} width={"100%"} sx={{height: "100%", width: "100%", position: "absolute", top: 0, bottom: 0, left: 0, right: 0}}/>
        </Box>
    );
}
