import * as React from "react";
import {Layout} from "../Common/components/Layout";
import {Container} from "@mui/material";
import {useParams} from "react-router-dom";
import {ArticleContent} from "./components/ArticleContent";
import {useArticle} from "../Common/domain/hooks/useArticle";
import {uiHelper} from "../Common/domain/helpers/uiHelper";
import {Metas} from "../Common/components/Metas";

/**
 * La page d'un article
 * @constructor
 */
export function Article() {
    const {articleId} = useParams();
    const [article] = useArticle({articleId});

    if (undefined === article) {
        return <></>;
    }

    return (
        <>
            <Metas
                title={article.title}
                description={article.subTitle}
                image={article.homepageImageUrl}
            />
            <Layout themeOverride={uiHelper.getCustomThemeForArticle(article)}>
                <Container maxWidth={"sm"}>
                    <ArticleContent article={article}/>
                </Container>
            </Layout>
        </>
    );
}
