import * as React from "react";
import {Box, Typography, Link, ThemeProvider} from "@mui/material";
import {DatagoraImage} from "../../Common/components/DatagoraImage";
import {Article} from "../../api/interfaces/Article";
import {CATEGORIES} from "../../Common/domain/helpers/categoriesHelpers";
import {Link as RouterLink} from "react-router-dom";
import {uiHelper} from "../../Common/domain/helpers/uiHelper";
import {DatagoraSkeleton} from "../../Common/components/navigation/DatagoraSkeleton";

/**
 * Carte section à la une de la HP
 * @constructor
 */
export function FeaturedThumbnail({article, size}: {article: Article | null, size: string}) {

    function getInfosSize(type) {
        const infosSize = {
            "titleVariant": "",
            "titleComponent": "",
            "categoryVariant": "",
            "categoryBulletSize": 0,
            "skeletonPictureHeight": 0
        };
        switch (size) {
            case "small":
                infosSize["titleVariant"] = "h5";
                infosSize["titleComponent"] = "h4";
                infosSize["categoryVariant"] = "subtitle2";
                infosSize["categoryBulletSize"] = 7;
                infosSize["skeletonPictureHeight"] = 205;
                break;
            case "medium":
                infosSize["titleVariant"] = "h4";
                infosSize["titleComponent"] = "h3";
                infosSize["categoryVariant"] = "subtitle1";
                infosSize["categoryBulletSize"] = 8;
                infosSize["skeletonPictureHeight"] = 324;
                break;
            case "large":
                infosSize["titleVariant"] = "h3";
                infosSize["titleComponent"] = "h2";
                infosSize["categoryVariant"] = "body2";
                infosSize["categoryBulletSize"] = 12;
                infosSize["skeletonPictureHeight"] = 680;
                break;
            default:
                infosSize["titleVariant"] = "h3";
                infosSize["titleComponent"] = "h2";
                infosSize["categoryVariant"] = "body2";
                infosSize["categoryBulletSize"] = 12;
                infosSize["skeletonPictureHeight"] = 680;
                break;
        }
        return infosSize[type];
    }

    if (!article) {
        return (
            <DatagoraSkeleton width={"100%"} aspectRatio={"1/1"}/>
        );
    }

    return (
        <ThemeProvider theme={CATEGORIES[article.category].theme}>
            <Link
                component={RouterLink}
                to={uiHelper.getArticleLinkPath(article)}
                sx={{
                    textDecoration: "none",
                    transition: "opacity .3s ease",
                    "&:hover": {
                        opacity: .8,
                    }
                }}
            >
                <Box>
                    <DatagoraImage
                        src={article.homepageImageUrl}
                        alt={article.title}
                    />
                    <Box display={"flex"} flexDirection={"column-reverse"}>
                        <Typography variant={getInfosSize("titleVariant")} component={"h2"} mt={1}>
                            {article.title}
                        </Typography>
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} pt={2}>
                            <Box
                                width={getInfosSize("categoryBulletSize")}
                                minWidth={getInfosSize("categoryBulletSize")}
                                height={getInfosSize("categoryBulletSize")}
                                bgcolor={"primary.main"}
                                borderRadius={"50%"}
                                mr={1}
                            />
                            <Typography
                                variant={getInfosSize("categoryVariant")}
                                component={"h3"}
                                color={"primary.main"}
                                sx={{
                                    textTransform: "uppercase",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden"
                                }}
                            >
                                {article.categoryLabel}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Link>
        </ThemeProvider>
    );
}
