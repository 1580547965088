import * as React from "react";
import {Box, Typography, TypographyProps} from "@mui/material";

type TitleGradientProps = TypographyProps & {
    children: string,
    component: React.ElementType,
    color1?: string,
    color2?: string,
};

/**
 * Titre dégradé
 * @constructor
 */
export function TitleGradient({children, variant, align, component, color1, color2} : TitleGradientProps): React.ReactElement {
    return (
        <Box textAlign={align}>
            <Typography 
                display={"inline-block"}
                variant={variant}
                component={component}
                sx={(theme) => ({
                    backgroundImage: `linear-gradient(95.19deg, ${color1 ?? theme.palette.primary.main} 0%, ${color2 ?? theme.palette.secondary.main} 100%)`,
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    color: theme.palette.primary.main,
                })}
            >
                {children}
            </Typography>
        </Box>
    );
}
