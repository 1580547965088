import * as React from "react";
import {Layout} from "../Common/components/Layout";
import {HomeFeatured} from "./components/HomeFeatured";
import {HomeOriginalCreations} from "./components/HomeOriginalCreations";
import {Box, Container, Divider} from "@mui/material";
import {useHomepageContent} from "../Common/domain/hooks/useHomepageContent";
import {Metas} from "../Common/components/Metas";

/**
 * La page d'accueil
 * @constructor
 */
export function Home() {
    const [homepageArticles, originalCreations] = useHomepageContent();

    return (
        <>
            <Metas/>
            <Layout>
                <Container component={"section"}>
                    {homepageArticles &&
                        <Box mb={8}>
                            <HomeFeatured homepageArticles={homepageArticles}/>
                        </Box>
                    }
                    {homepageArticles && 0 !== originalCreations.length &&
                        <Divider/>
                    }
                    {0 !== originalCreations.length &&
                        <Box mt={8}>
                            <HomeOriginalCreations originalCreations={originalCreations}/>
                        </Box>
                    }
                </Container>
            </Layout>
        </>
    );
}
