import * as React from "react";
import {getArticles} from "../../../api/consumer";
import {Article} from "../../../api/interfaces/Article";
import {uiHelper} from "../helpers/uiHelper";

export const useArticles = ({categoryId}: {categoryId: string}): [Article[]] => {
    const [articles, setArticles] = React.useState<Article[]>([]);

    React.useEffect(() => {
        getArticles(uiHelper.getCategoryKeyFromCategoryId(categoryId), false)
            .then(articles => {
                setArticles(articles);
            });
    }, [categoryId]);

    return [articles];
};
