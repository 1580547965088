import * as React from "react";
import {useTheme} from "@mui/material/styles";

/**
 * Icon Svg Social
 * @constructor
 */
export function DatagoraSocialIcon({path, color, size = "24"}: {path: string, color?: string, size?: string}) {
    const theme = useTheme();

    return (
        <svg
            xmlns={"http://www.w3.org/2000/svg"}
            width={size}
            height={size}
            viewBox={"0 0 24 24"}
            fill={color ?? theme.palette.grey[200]}
        >
            <path d={path}/>
        </svg>
    );
}
