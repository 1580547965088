import * as React from "react";
import {Helmet} from "react-helmet";
// @ts-ignore
import DatagoraLogo from "../../assets/images/datagora_logo_512x512.png";

export function Metas({
    title = "Accueil | Articles à la une et séries exclusives",
    description = "Datagora démocratise les travaux issus de sources fiables afin d'enrichir le débat public. Datagora permet de mieux comprendre les grands enjeux économiques, sociaux ou environnementaux au travers de chiffres et graphiques accessibles et rigoureux. Retour aux sources.",
    image = DatagoraLogo
}: {
    title?: string;
    description?: string,
    image?: any
}) {
    return (
        <>
            <Helmet>
                <title>Datagora | {title}</title>
                <meta name="og:title" content={title}/>
                <meta name="twitter:title" content={title}/>
                <meta name="description" content={description}/>
                <meta name="og:description" content={description}/>
                <meta name="twitter:description" content={description}/>
                <meta name="site_name" content="Datagora"/>
                <meta name="twitter:card" property="twitter:card" content="summary_large_image"/>
                <link rel="image_src" href={image}/>
                <meta property="og:image" content={image}/>
                <meta property="twitter:image" content={image}/>
            </Helmet>
        </>
    );
}
