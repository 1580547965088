import * as React from "react";
import {Layout} from "../Common/components/Layout";

/**
 * La page "équipe"
 * @constructor
 */
export function Team() {
    return (
        <Layout>
            <>
				Team
            </>
        </Layout>
    );
}
