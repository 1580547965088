import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Box} from "@mui/material";
import {DatagoraImage} from "../../Common/components/DatagoraImage";
import {OriginalCreationMenuStepper} from "./OriginalCreationMenuStepper";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {OriginalCreation} from "../../api/interfaces/OriginalCreation";

export function OriginalCreationMenu({originalCreation, onClick, currentArticleIndex}: {originalCreation: OriginalCreation, onClick: (i: number) => void, currentArticleIndex: number}) {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
    const lastStepRef = useRef(null);
    const [lastStepRefHeight, setLastStepRefHeight] = useState(0);

    useEffect(() => {
        if (null === lastStepRef?.current) {
            return;
        }
        setLastStepRefHeight(lastStepRef.current.offsetHeight);
    }, [lastStepRef]);

    if (!originalCreation) {
        return <></>;
    }

    return (
        <>
            <Box
                pr={2}
                pl={"18px"}
                pb={4}
                pt={9}
                borderRadius={2}
                mt={isLargeScreen ? 8 : 11}
                mb={isLargeScreen ? 0 : 4}
                position={isLargeScreen ? "sticky" : "relative"}
                top={isLargeScreen ? 270 : "inherit"}
                bgcolor={"background.paper"}
                sx={theme => ({
                    border: `1px solid ${theme.palette.divider}`,
                    "&:after": {
                        content: "''",
                        width: 16,
                        height: `calc(100% - 24px - ${lastStepRefHeight / 2}px)`,
                        background: theme.palette.divider,
                        position: "absolute",
                        left: 16,
                        top: 0,
                        borderRadius: "0 0 8px 8px",
                        zIndex: 0
                    },
                })}
            >
                <Box width={192} position={"absolute"} left={"50%"} top={0} sx={{transform: "translate(-50%, -50%)"}}>
                    <DatagoraImage
                        src={originalCreation.visualImageUrl}
                        alt={originalCreation.title}
                        aspectRatio={"12/5"}
                    />
                </Box>
                <OriginalCreationMenuStepper
                    ref={lastStepRef}
                    articles={originalCreation.articles}
                    onClick={onClick}
                    currentArticleIndex={currentArticleIndex}
                />
            </Box>
        </>
    );
}
