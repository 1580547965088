import * as React from "react";
import {Layout} from "../Common/components/Layout";
import {Container, Typography} from "@mui/material";
import {Metas} from "../Common/components/Metas";

/**
 * La page "mentions légales"
 * @constructor
 */
export function Legals() {
    return (
        <>
            <Metas title={"Mentions légales"}/>
            <Layout>
                <Container>
                    <Typography variant={"h1"} mb={4}>
                        Mentions Légales
                    </Typography>
                    <Typography gutterBottom>
                        Editeur du site : Datagora, société par actions simplifiées (SAS)
                    </Typography>
                    <Typography gutterBottom>
                        Siège social : 101 rue de Sèvres, 75006 Paris
                    </Typography>
                    <Typography gutterBottom>
                        Contact : contact@datagora.fr
                    </Typography>
                    <Typography gutterBottom>
                        Immatriculation au Registre du Commerce et des Sociétés de Paris sous le n° 833 189 657
                    </Typography>
                    <Typography gutterBottom>
                        Numéro de TVA : FR39833189657
                    </Typography>
                    <Typography gutterBottom>
                        Capital social : 5000€
                    </Typography>
                    <Typography gutterBottom>
                        Directeur de la publication : Timothée GIDOIN, directeur général
                    </Typography>
                    <Typography>
                        Hébergeur : Evolix, 269 Avenue Daumesnil, 75012 Paris, 01 85 08 51 91
                    </Typography>
                </Container>
            </Layout>
        </>
    );
}
