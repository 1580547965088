import * as React from "react";
import {Typography, Box, Link, GlobalStyles} from "@mui/material";
import {TitleGradient} from "../../Common/components/TitleGradient";
import {NextArticleThumbnail} from "./NextArticleThumbnail";
import {Article} from "../../api/interfaces/Article";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {uiHelper} from "../../Common/domain/helpers/uiHelper";
import {OriginalCreation} from "../../api/interfaces/OriginalCreation";
import {ArticleMedia} from "./ArticleMedia";
import {ArticleShare} from "./ArticleShare";
import {ArticleSocialMediaFollow} from "./ArticleSocialMediaFollow";

/**
 * Le contenu d'un article
 * @constructor
 */
export function ArticleContent({article, currentArticleIndex,  originalCreation, children}: {article: Article, currentArticleIndex?: number; originalCreation?: OriginalCreation; children?: {props}|null}) {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            <TitleGradient variant={"h2"} component={"h1"} align={"center"}>
                {article.title}
            </TitleGradient>

            <Typography variant={"h4"} component={"h2"} align={"center"} mb={4} mt={2}>
                {article.subTitle}
            </Typography>

            {children && children}

            <Box mb={mobile ? 2 : 3.5} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant={"caption"} color={"text.secondary"} textTransform={"capitalize"}>
                    {new Date(article.publicationDate).toLocaleString("fr-FR", {day: "numeric", month: "numeric", "year": "numeric"})}
                </Typography>

                <ArticleShare article={article}/>
            </Box>

            <Box mb={4} borderRadius={2}>
                <ArticleMedia article={article}/>
            </Box>

            {article.introduction &&
                <TitleGradient variant={"h4"} component={"h3"} align={"left"}>
                    {article.introduction}
                </TitleGradient>
            }

            <Box my={4}>
                <GlobalStyles styles={uiHelper.getCkeditorStyles(theme)}/>
                <div className={"ckEditorContent"} dangerouslySetInnerHTML={{__html: article.content}}/>
            </Box>

            <Box
                mt={4}
                py={4}
                sx={(theme) => ({
                    borderTop: `1px solid ${theme.palette.divider}`
                })}
            >
                <Box display={"flex"}
                    sx={{
                        flexDirection: {xs: "column", md: "row"}
                    }}>
                    <Typography mb={mobile ? 1 : 0} variant={"h6"} component={"p"} color={"text.secondary"} sx={{minWidth: "80px"}} mr={mobile ? 0 : 5}>
                        Le rapport
                    </Typography>
                    <Link href={article.reportLink} target={"_blank"} color={"text.secondary"} sx={{lineHeight: "24px"}}>
                        <Typography variant={"body2"} color={"text.secondary"}>
                            {article.report}
                        </Typography>
                    </Link>
                </Box>

                <Box display={"flex"} flexDirection={mobile ? "column" : "row"} mt={2}>
                    <Typography mb={mobile ? 1 : 0} variant={"h6"} component={"p"} color={"text.secondary"} sx={{minWidth: "80px"}} mr={mobile ? 0 : 5}>
                        La source
                    </Typography>
                    <Typography variant={"body2"} color={"text.secondary"}>
                        {article.source}
                    </Typography>
                </Box>
            </Box>

            <ArticleSocialMediaFollow/>

            <NextArticleThumbnail currentArticle={article} originalCreationNextArticle={originalCreation?.articles[currentArticleIndex + 1]}/>
        </>
    );
}
