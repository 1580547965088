import * as React from "react";
import {useTheme} from "@mui/material/styles";

/**
 * Le logo du header
 * @constructor
 */
export function FooterLogo(): React.ReactElement {
    const theme = useTheme();    

    return (
        <svg xmlns={"http://www.w3.org/2000/svg"} xmlnsXlink={"http://www.w3.org/1999/xlink"} width={"32"} height={"32"} viewBox={"0 0 48 48"}>
            <defs>
                <rect id={"rect"} width={"48"} height={"48"} x={"0"} y={"0"}/>
                <mask id={"mask"} maskContentUnits={"userSpaceOnUse"} maskUnits={"userSpaceOnUse"}>
                    <rect width={"46"} height={"48"} x={"0"} y={"0"} fill={"black"}/>
                    <use fill={"white"} xlinkHref={"#rect"}/>
                </mask>
                <linearGradient id={"gradient"} x1={"0"} x2={"48"} y1={"24.039"} y2={"24.039"} gradientUnits={"userSpaceOnUse"}>
                    <stop offset={"0"} stopColor={theme.palette.primary.main}/>
                    <stop offset={"1"} stopColor={theme.palette.secondary.main}/>
                </linearGradient>
            </defs>
            <g>
                <g mask={"url(#mask)"}>
                    <rect width={"48"} height={"48"} x={"0"} y={"0"} fill={"none"} rx={"0"} ry={"0"}/>
                    <path fill={`${"#FFFFFF" === theme.palette.text.primary ? theme.palette.common.white : "url(#gradient)"}`} fillRule={"evenodd"} d={"M5.3,17.3c0.5,0.7,1.5,0.9,2.2,0.5l0,0l15.2-8.9L38,17.8c0.8,0.4,1.7,0.1,2.1-0.8c0.3-0.7,0.1-1.5-0.5-1.9L23.5,5.7c-0.5-0.3-1.2-0.3-1.6,0l-16,9.4C5.1,15.6,4.8,16.5,5.3,17.3z M32.3,18.1c0.9-0.1,1.6,0.6,1.7,1.4V32c-0.1,0.8-0.7,1.4-1.5,1.4c-0.9,0.1-1.6-0.6-1.7-1.4V19.6C30.8,18.8,31.4,18.1,32.3,18.1z M26,21.1c0.9,0,1.6,0.7,1.6,1.6V32c0,0.9-0.7,1.5-1.6,1.5s-1.6-0.7-1.6-1.5v-9.4C24.3,21.8,25.1,21.1,26,21.1z M19.6,24.3c0.9,0,1.6,0.7,1.6,1.6v6.2c0,0.9-0.7,1.6-1.6,1.6S18,33.1,18,32.1v-6.2C17.9,24.9,18.6,24.3,19.6,24.3z M14.7,28.9c-0.1-0.9-0.9-1.5-1.7-1.4c-0.8,0.1-1.5,0.7-1.5,1.4V32c0.1,0.9,0.9,1.5,1.7,1.4c0.8-0.1,1.5-0.7,1.5-1.4V28.9z M38.8,39.9c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.5-1.6-1.5H6.7c-0.9,0-1.6,0.7-1.6,1.5c0,0.9,0.7,1.6,1.6,1.6H38.8z"}/>
                </g>
            </g>
        </svg>
    );
}
