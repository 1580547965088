import * as React from "react";
import {Box, Grid, Typography} from "@mui/material";

type ColorsByCategoryProps = {title: string, icon: React.ReactElement; primaryColor: string; secondaryColor: string; backgroundColor: string;}

/**
 * Ce composant affiche le dégradé et la couleur de fond pour une categorie + une icone
 */
export function ColorsByCategory({title, icon, primaryColor, secondaryColor, backgroundColor}: ColorsByCategoryProps) {

    return (
        <>
            <Typography variant="h4" pb={2}>{title}</Typography>
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <Box component={"span"} display={"flex"} justifyContent={"center"} alignItems={"center"}
                        sx={{
                            backgroundColor: primaryColor,
                            borderRadius: "8px",
                            stroke: "white",
                            height: 80,
                            maxWidth: 80,
                        }}>
                        {icon}
                    </Box>
                    <Box pt={1}>
                        <Typography variant={"caption"}>{primaryColor}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={8} spacing={2}>
                    <Box pb={2}>
                        <Box
                            component={"span"}
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            sx={{
                                boxSizing: "border-box",
                                background: `linear-gradient(90deg, ${primaryColor} 0%, ${secondaryColor} 100%)`,
                                borderRadius: "8px",
                                color: "#FFF",
                                height: 32,
                                maxWidth: 192,
                                padding: "6px 8px",
                                alignItems: "center",
                            }}>
                            <Typography variant={"caption"}>{primaryColor}</Typography>
                            <Typography variant={"caption"}>{secondaryColor}</Typography>
                        </Box>
                    </Box>

                    <Box component={"span"} display={"flex"} justifyContent={"space-between"}
                        sx={{
                            boxSizing: "border-box",
                            backgroundColor: backgroundColor,
                            borderRadius: "8px",
                            border: "1px solid #E0E0E0",
                            color: "#000",
                            height: 32,
                            maxWidth: 192,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: "6px 8px",
                            alignItems: "center",
                        }}>
                        <Typography variant={"caption"}>Background</Typography>
                        <Typography variant={"caption"}>{backgroundColor}</Typography>
                    </Box>
                </Grid>
            </Grid></>

    );
}

