import * as React from "react";
import {Feather, Flag, Headphones, Mic, TrendingUp} from "react-feather";
import {
    categoryEnvironmentTheme,
    categoryEconomyTheme,
    categoryPoliticTheme,
    categorySocietyTheme,
    categoryInternationalTheme
} from "../../theme/theme";

export const CATEGORIES = {
    "ENVIRONMENT": {
        slug: "environnement",
        label: "Environnement",
        theme: categoryEnvironmentTheme,
        icon: <Feather/>,
        primary: "#62C92A",
        secondary: "#0084FF",
        background: "#FBFFF8",
    },
    "ECONOMY": {
        slug: "economie",
        label: "Économie",
        theme: categoryEconomyTheme,
        icon: <TrendingUp/>,
        primary: "#FF2E48",
        secondary: "#C03588",
        background: "#FFFAFB",
    },
    "POLITIC": {
        slug: "politique",
        label: "Politique",
        theme: categoryPoliticTheme,
        icon: <Mic/>,
        primary: "#0084FF",
        secondary: "#C03588",
        background: "#F7F9FB",
    },
    "SOCIETY": {
        slug: "societe",
        label: "Société",
        theme: categorySocietyTheme,
        icon: <Headphones/>,
        primary: "#FA9917",
        secondary: "#FF2E48",
        background: "#FFFBF6",
    },
    "INTERNATIONAL": {
        slug: "international",
        label: "International",
        theme: categoryInternationalTheme,
        icon: <Flag/>,
        primary: "#C03588",
        secondary: "#FA9917",
        background: "#FFF8FC",
    }
};
