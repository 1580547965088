import * as React from "react";
import {Box, Button, Container, Grid, Link} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {HeaderLogo} from "./HeaderLogo";
import {HeaderGradientLine} from "./HeaderGradientLine";
import {useTheme} from "@mui/material/styles";
import {Menu} from "react-feather";

/**
 * La nav commune aux pages
 * @constructor
 */
export function MainNav({onClick, isMobile}: {onClick: () => void, isMobile: boolean}) {
    const theme = useTheme();

    return (
        <Box position={"fixed"} width={"100%"} zIndex={10} bgcolor={"background.default"}>
            <HeaderGradientLine/>
            <Box
                sx={(theme) => ({
                    backgroundColor: "background.paper",
                    borderBottom: `1px solid ${theme.palette.divider}`
                })}
            >
                <Container maxWidth={"lg"}>
                    <Grid container>
                        <Grid item xs={3} alignSelf={"center"}>
                            {isMobile ? (
                                <Button
                                    disableRipple={true}
                                    variant={"text"}
                                    onClick={onClick}
                                    sx={{
                                        border: 0,
                                        padding: 0,
                                        minWidth: "auto",
                                        "&:hover": {
                                            background: "none",
                                        }
                                    }}
                                >
                                    <Menu color={theme.palette.text.primary}/>
                                </Button>
                            ) : (
                                <Link href={"https://www.plateforme.datagora.fr/"} target={"_blank"} rel={"noreferrer noopener"} underline={"none"} component={"a"}>
                                    <Button variant={"contained"} color={"secondary"}>Plateforme</Button>
                                </Link>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <Link
                                component={RouterLink} to={"/"}
                                py={2}
                                display={"flex"}
                                justifyContent={"center"}
                                aria-label={"Logo de Datagora media"}
                            >
                                <HeaderLogo/>
                            </Link>
                        </Grid>
                        <Grid item xs={0} md={3} alignSelf={"center"} sx={{display: {xs: "none", md: "flex"}, justifyContent: "flex-end"}}>
                            <Link component={RouterLink} to={"/contact"} underline={"none"}>
                                <Button variant={"contained"} color={"primary"}>Contact & partenariat</Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}
