import * as React from "react";
import {Typography, Box, Link} from "@mui/material";
import {DatagoraImage} from "../../Common/components/DatagoraImage";
import {useNextArticle} from "../../Common/domain/hooks/useNextArticle";
import {Article} from "../../api/interfaces/Article";
import {Link as RouterLink} from "react-router-dom";
import {uiHelper} from "../../Common/domain/helpers/uiHelper";

/**
 * La vignette d'un article similaire
 * @constructor
 */
export function NextArticleThumbnail({currentArticle, originalCreationNextArticle}: {currentArticle: Article, originalCreationNextArticle?: Article}) {
    const [nextArticle] = useNextArticle(currentArticle.id, originalCreationNextArticle);

    if (!nextArticle) {
        return <></>;
    }

    return (
        <section>
            <Typography variant={"h3"} component={"h1"} mb={4} mt={8}>
                Article suivant
            </Typography>
            <Link
                component={RouterLink}
                to={uiHelper.getArticleLinkPath(nextArticle)}
                underline={"none"}
            >
                <Box display={"flex"}>
                    <Box sx={{ 
                        width: {xs: "100px" , sm: "192px"}, 
                        minWidth: {xs: "100px" , sm: "192px"}, 
                        marginRight: {xs: 2, sm: 4}
                    }}
                    >
                        <DatagoraImage
                            src={nextArticle.homepageImageUrl}
                            alt={nextArticle.title}
                        />
                    </Box>
                    <Box>
                        <Typography variant={"h4"} component={"h2"} mb={1} sx={{fontSize: 22, lineHeight: "26px"}}>
                            {nextArticle.title}
                        </Typography>
                        <Typography variant={"caption"} component={"h3"} mb={1}>
                            {nextArticle.subTitle}
                        </Typography>
                        <Box display={"flex"} flexDirection={"row"} pt={1}>
                            <Typography variant={"caption"} color={"primary"} mr={0.5}>
                                {nextArticle.origin}
                            </Typography>
                            <Typography variant={"caption"} color={"text.secondary"} mr={0.5}>
                                •
                            </Typography>
                            <Typography variant={"caption"} color={"text.secondary"} textTransform={"capitalize"} mr={0.5}>
                                {new Date(nextArticle.publicationDate).toLocaleString("default", {month: "long"})}
                            </Typography>
                            <Typography variant={"caption"} color={"text.secondary"}>
                                {new Date(nextArticle.publicationDate).getFullYear()}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Link>
        </section>
    );
}
