import * as React from "react";
import {Article} from "../../api/interfaces/Article";
import {DatagoraVideoPlayer} from "../../Common/components/DatagoraVideoPlayer";
import {ArticleSwiper} from "./ArticleSwiper";
import {DatagoraImage} from "../../Common/components/DatagoraImage";

/**
 * Le média d'un article
 * peut être une vidéo, un swiper ou une image simple
 * @constructor
 */
export function ArticleMedia({article}: {article: Article}) {
    if ("VIDEO" === article.mediaType) {
        return <DatagoraVideoPlayer article={article} />;
    } else if (1 < article.carrouselImages.length) {
        return <ArticleSwiper article={article} />;
    } else {
        return <DatagoraImage
            src={1 === article.carrouselImages.length ? article.carrouselImages[0].imageFileUrl : article.homepageImageUrl}
            alt={article.title}
        />;
    }
}
