import * as React from "react";
import {Box} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination, Navigation} from "swiper";
import {DatagoraImage} from "../../Common/components/DatagoraImage";
import {ChevronRight, ChevronLeft} from "react-feather";
import {theme} from "../../Common/theme/theme";
import {Article} from "../../api/interfaces/Article";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../style/ArticleSwiper.css";

/**
 * Le carousel des images d'un article
 * @constructor
 */
export function ArticleSwiper({article}: { article: Article }) {

    if (null === article.carrouselImages) {
        return <></>;
    }

    return (
        <>
            <Swiper
                onUpdate={(swiper) => {
                    swiper.activeIndex = 0;
                }}
                pagination={{
                    type: "custom",
                    clickable: true,
                    renderCustom: function(swiper, current, total) {
                        let customPaginationHtml = "";
                        for (let i = 0; i < total; i++) {
                            if (i === (current - 1)) {
                                customPaginationHtml += "<span role='button' style='width: calc(100% / " + total + ");' class='swiper-pagination-clickable swiper-pagination-customs swiper-pagination-customs-active'></span>";
                            } else {
                                customPaginationHtml += "<span role='button' style='width: calc(100% / " + total + ");' class='swiper-pagination-clickable swiper-pagination-customs'></span>";
                            }
                        }
                        return customPaginationHtml;
                    }
                }}
                navigation={{
                    prevEl: ".swiperButtonPrev",
                    nextEl: ".swiperButtonNext",
                }}
                modules={[Pagination, Navigation]}
                spaceBetween={32}
                style={{position: "relative"}}
            >
                {article.carrouselImages.map((image, index) => (
                    <SwiperSlide key={`swiper_${index}`}>
                        <DatagoraImage
                            src={image.imageFileUrl}
                            alt={article.title + " " + index}
                        />
                    </SwiperSlide>
                ))}
                <Box
                    role={"button"}
                    className={"swiperButtonPrev"}
                    position={"absolute"}
                    left={0}
                    top={"50%"}
                    zIndex={10}
                    width={32}
                    height={80}
                    color={theme.palette.common.white}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    pr={1}
                    sx={{
                        transform: "translateY(-50%)",
                        backgroundColor: "rgba(0,0,0,0.5)",
                        borderRadius: "0 40px 40px 0",
                        cursor: "pointer"
                    }}
                >
                    <ChevronLeft/>
                </Box>
                <Box
                    role={"button"}
                    className={"swiperButtonNext"}
                    position={"absolute"}
                    right={0}
                    top={"50%"}
                    zIndex={10}
                    width={32}
                    height={80}
                    color={theme.palette.common.white}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    pl={1}
                    sx={{
                        transform: "translateY(-50%)",
                        backgroundColor: "rgba(0,0,0,0.5)",
                        borderRadius: "40px 0 0 40px",
                        cursor: "pointer"
                    }}
                >
                    <ChevronRight/>
                </Box>
            </Swiper>
        </>

    );
}
