import * as React from "react";
import {Layout} from "../Common/components/Layout";
import {Container, Grid} from "@mui/material";
import {ArticleContent} from "../Article/components/ArticleContent";
import {OriginalCreationMenu} from "./components/OriginalCreationMenu";
import {useOriginalCreation} from "../Common/domain/hooks/useOriginalCreation";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import {uiHelper} from "../Common/domain/helpers/uiHelper";
import {Metas} from "../Common/components/Metas";

/**
 * La page d'une série d'article
 * @constructor
 */
export function OriginalCreation() {
    const theme = useTheme();
    const navigate = useNavigate();
    const [originalCreation, currentArticle, currentArticleIndex, setCurrentArticleIndex] = useOriginalCreation();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

    const updateCurrentArticle = (index) => {
        setCurrentArticleIndex(index);
        navigate(uiHelper.getArticleLinkPath(originalCreation.articles[index]));
    };

    if (undefined === originalCreation || null === currentArticle) {
        return <></>;
    }
    
    return (
        <>
            <Metas
                title={`${originalCreation.title} | ${currentArticle.title}`}
                description={currentArticle.subTitle}
                image={originalCreation.homepageImageUrl}
            />
            <Layout themeOverride={uiHelper.getCustomThemeForArticle(currentArticle)}>
                <Container>
                    <Grid container columnSpacing={4}>
                        <Grid item xl={3} display={{xs: "none", xl: "block"}} />
                        <Grid item xs={12} xl={6}>
                            <Container maxWidth={"sm"}>
                                <ArticleContent article={currentArticle} originalCreation={originalCreation} currentArticleIndex={currentArticleIndex}>
                                    {!isLargeScreen ? (
                                        <OriginalCreationMenu
                                            originalCreation={originalCreation}
                                            onClick={updateCurrentArticle}
                                            currentArticleIndex={currentArticleIndex}
                                        />
                                    ) : null}
                                </ArticleContent>
                            </Container>
                        </Grid>
                        <Grid xl={3} display={{xs: "none", xl: "block"}}>
                            <OriginalCreationMenu
                                originalCreation={originalCreation}
                                onClick={updateCurrentArticle}
                                currentArticleIndex={currentArticleIndex}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Layout>
        </>
    );
}
