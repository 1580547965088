import {CATEGORIES} from "./categoriesHelpers";
import {createTheme, Theme} from "@mui/material";
import {theme} from "../../theme/theme";
import {Article} from "../../../api/interfaces/Article";

export const uiHelper = {
    /**
     *tracé des icones des réseaux sociaux
     */
    facebookIconPath: "M24 12.07C24 5.41 18.63 0 12 0S0 5.4 0 12.07C0 18.1 4.39 23.1 10.13 24v-8.44H7.08v-3.49h3.04V9.41c0-3.02 1.8-4.7 4.54-4.7 1.31 0 2.68.24 2.68.24v2.97h-1.5c-1.5 0-1.96.93-1.96 1.89v2.26h3.32l-.53 3.5h-2.8V24C19.62 23.1 24 18.1 24 12.07",
    twitterIconPath: "M24 4.37a9.6 9.6 0 0 1-2.83.8 5.04 5.04 0 0 0 2.17-2.8c-.95.58-2 1-3.13 1.22A4.86 4.86 0 0 0 16.61 2a4.99 4.99 0 0 0-4.79 6.2A13.87 13.87 0 0 1 1.67 2.92 5.12 5.12 0 0 0 3.2 9.67a4.82 4.82 0 0 1-2.23-.64v.07c0 2.44 1.7 4.48 3.95 4.95a4.84 4.84 0 0 1-2.22.08c.63 2.01 2.45 3.47 4.6 3.51A9.72 9.72 0 0 1 0 19.74 13.68 13.68 0 0 0 7.55 22c9.06 0 14-7.7 14-14.37v-.65c.96-.71 1.79-1.6 2.45-2.61z",
    linkedinIconPath: "M22.23 0H1.77C.8 0 0 .77 0 1.72v20.56C0 23.23.8 24 1.77 24h20.46c.98 0 1.77-.77 1.77-1.72V1.72C24 .77 23.2 0 22.23 0zM7.27 20.1H3.65V9.24h3.62V20.1zM5.47 7.76h-.03c-1.22 0-2-.83-2-1.87 0-1.06.8-1.87 2.05-1.87 1.24 0 2 .8 2.02 1.87 0 1.04-.78 1.87-2.05 1.87zM20.34 20.1h-3.63v-5.8c0-1.45-.52-2.45-1.83-2.45-1 0-1.6.67-1.87 1.32-.1.23-.11.55-.11.88v6.05H9.28s.05-9.82 0-10.84h3.63v1.54a3.6 3.6 0 0 1 3.26-1.8c2.39 0 4.18 1.56 4.18 4.89v6.21z",
    instagramIconPath: "M16.98 0a6.9 6.9 0 0 1 5.08 1.98A6.94 6.94 0 0 1 24 7.02v9.96c0 2.08-.68 3.87-1.98 5.13A7.14 7.14 0 0 1 16.94 24H7.06a7.06 7.06 0 0 1-5.03-1.89A6.96 6.96 0 0 1 0 16.94V7.02C0 2.8 2.8 0 7.02 0h9.96zm.05 2.23H7.06c-1.45 0-2.7.43-3.53 1.25a4.82 4.82 0 0 0-1.3 3.54v9.92c0 1.5.43 2.7 1.3 3.58a5 5 0 0 0 3.53 1.25h9.88a5 5 0 0 0 3.53-1.25 4.73 4.73 0 0 0 1.4-3.54V7.02a5 5 0 0 0-1.3-3.49 4.82 4.82 0 0 0-3.54-1.3zM12 5.76c3.39 0 6.2 2.8 6.2 6.2a6.2 6.2 0 0 1-12.4 0 6.2 6.2 0 0 1 6.2-6.2zm0 2.22a3.99 3.99 0 0 0-3.97 3.97A3.99 3.99 0 0 0 12 15.92a3.99 3.99 0 0 0 3.97-3.97A3.99 3.99 0 0 0 12 7.98zm6.44-3.77a1.4 1.4 0 1 1 0 2.8 1.4 1.4 0 0 1 0-2.8z",
    tikTokIconPath: "M19.32,5.56c-.15-.08-.3-.16-.44-.26-.42-.28-.8-.6-1.14-.97-.85-.97-1.17-1.96-1.28-2.65h0c-.1-.57-.06-.94-.05-.94h-3.86V15.69c0,.2,0,.4,0,.59,0,.02,0,.05,0,.07,0,.01,0,.02,0,.03h0c-.08,1.1-.7,2.07-1.65,2.61-.49,.28-1.04,.42-1.6,.42-1.8,0-3.26-1.47-3.26-3.28s1.46-3.28,3.26-3.28c.34,0,.68,.05,1,.16v-3.93c-1.98-.26-3.99,.33-5.53,1.62-.67,.58-1.23,1.27-1.65,2.04-.16,.28-.78,1.41-.85,3.25-.05,1.04,.27,2.12,.41,2.57h0c.09,.27,.46,1.17,1.05,1.92,.48,.61,1.04,1.14,1.67,1.58h0c1.87,1.27,3.94,1.19,3.94,1.19,.36-.01,1.56,0,2.93-.65,1.52-.72,2.38-1.79,2.38-1.79,.55-.64,.99-1.37,1.3-2.15,.35-.92,.47-2.02,.47-2.46v-7.93s.67,.44,.67,.44c0,0,.9,.58,2.3,.95,1.01,.27,2.36,.32,2.36,.32v-3.84c-.48,.05-1.44-.1-2.43-.59Z",
    /**
     * liens des réseaux sociaux Datagora
     */
    datagoraInstagramLink: "https://www.instagram.com/datagora.fr",
    datagoraLinkedInLink: "https://fr.linkedin.com/company/datagora.fr",
    datagoraTwitterLink: "https://twitter.com/datagora",
    datagoraTikTokLink: "https://www.tiktok.com/@datagora.fr",
    /**
     * Les styles pour le raw html du CK editor
     * @param theme
     */
    getCkeditorStyles: (theme) => ({
        ".ckEditorContent": {
            ...theme.typography.body1,
            color: theme.palette.text.primary,
        },
        ".ckEditorContent p, .ckEditorContent em": {
            ...theme.typography.body1,
            color: theme.palette.text.primary,
        },
        ".ckEditorContent strong": {
            ...theme.typography.body1,
            color: theme.palette.text.primary,
            fontWeight: "bold",
        },
        ".ckEditorContent blockquote": {
            margin: "32px 0",
            fontSize: 22,
            lineHeight: "28px",
            fontWeight: 700,
            padding: "16px 32px",
            color: theme.palette.common.white,
            borderRadius: 24,
            borderBottomLeftRadius: 0,
            background: `linear-gradient(95.19deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`
        }
    }),
    /**
     * retourne une valeur "light" des couleurs
     * @param col
     * @param amt
     */
    getLightenColor: (col: string, amt = 30): string => {
        let usePound = false;
        if (col[0] === "#") {
            col = col.slice(1);
            usePound = true;
        }

        const num = parseInt(col,16);

        let r = (num >> 16) + amt;

        if (r > 255) {
            r = 255;
        } else if  (r < 0) {
            r = 0;
        }

        let b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) {
            b = 255;
        } else if  (b < 0) {
            b = 0;
        }

        let g = (num & 0x0000FF) + amt;

        if (g > 255) {
            g = 255;
        } else if  (g < 0) {
            g = 0;
        }

        return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    },
    /**
     * Le thème par défaut peut être overridé par le thème de la catégorie et par les valeurs custom du BO.
     * @param article
     */
    getCustomThemeForArticle: (article: Article): Theme => {
        const baseTheme = undefined !== article.category ? CATEGORIES[article.category].theme : theme;

        return createTheme(baseTheme, {
            palette: {
                primary: article.accentuationColorEnabled ? {
                    main: article.accentuationColor,
                    light: uiHelper.getLightenColor(article.accentuationColor),
                } : baseTheme.palette.primary,
                secondary: article.secondaryColorEnabled ? {
                    main: article.secondaryColor,
                    light: uiHelper.getLightenColor(article.secondaryColor),
                } : baseTheme.palette.secondary,
                background: article.backgroundColorEnabled ? {
                    default: article.backgroundColor,
                    paper: "rgba(255, 255, 255, 0.05)",
                } : baseTheme.palette.background,
                text: "DARK" === article.theme ? {
                    primary: "#FFFFFF",
                    secondary: "rgba(255, 255, 255, 0.5)"
                } : baseTheme.palette.text,
                divider: "DARK" === article.theme ? "rgba(255, 255, 255, 0.1)" : baseTheme.palette.divider
            }
        });
    },
    getArticleLinkPath: (article) => {
        /*@todo: originalCreationId n'est pas passé dans les articles d'une série, le passer permettrait de virer le String.replace() */
        if (article.originalCreation) {
            return `/series-exclusives/${article.originalCreation.replace("/api/original_creations/", "")}/${CATEGORIES[article.category].slug}/${article.id}`;
        }

        return `/categorie/${CATEGORIES[article.category].slug}/${article.id}`;
    },
    /**
     * retourne une valeur de padding en % à partir du string "aspectRatio"
     * @param aspectRatio
     */
    getPaddingFromAspectRatio: (aspectRatio?: "1/1" | "16/9" | "12/5" | "4/3"): string => {
        if (!aspectRatio) {
            return "unset";
        }
        const padding = aspectRatio.split("/");
        return ((Number(padding[1]) / Number(padding[0])) * 100).toString() + "%";
    },
    getCategoryKeyFromCategoryId: (categoryId?: string): string => {
        if (undefined === categoryId) {
            return "";
        }
        return Object.entries(CATEGORIES).find(([, catValues]) => catValues.slug === categoryId)[0];
    }
};
