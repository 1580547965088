import * as React from "react";
import {Grid, Box, Typography} from "@mui/material";
import {FeaturedThumbnail} from "./FeaturedThumbnail";
import {Homepage} from "../../api/interfaces/Homepage";

/**
 * Section "à la une" de la page d'accueil
 * @constructor
 */
export function HomeFeatured({homepageArticles}: {homepageArticles: Homepage}) {
    return (
        <section>
            <Box component={"header"} mb={2}>
                <Typography variant={"h1"}>
                    À la une
                </Typography>
            </Box>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <FeaturedThumbnail size={"large"} article={homepageArticles["article1"]}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <FeaturedThumbnail size={"medium"} article={homepageArticles["article2"]}/>
                        </Grid>
                        <Grid item xs={6}>
                            <FeaturedThumbnail size={"medium"} article={homepageArticles["article3"]}/>
                        </Grid>
                        <Grid item xs={4}>
                            <FeaturedThumbnail size={"small"} article={homepageArticles["article4"]}/>
                        </Grid>
                        <Grid item xs={4}>
                            <FeaturedThumbnail size={"small"} article={homepageArticles["article5"]}/>
                        </Grid>
                        <Grid item xs={4}>
                            <FeaturedThumbnail size={"small"} article={homepageArticles["article6"]}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    );
}
