import * as React from "react";
import {MainNav} from "./MainNav";
import {Box, Drawer} from "@mui/material";
import {CategoriesNav} from "./CategoriesNav";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

/**
 * Le header commun aux pages
 * @constructor
 */
export function Header() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    function toggleDrawer() {
        setIsMenuOpen(isOpen => !isOpen);
    }

    return (
        <Box component={"header"}>
            <MainNav onClick={toggleDrawer} isMobile={isMobile}/>
            {isMobile ? (
                <Drawer
                    anchor={"left"}
                    open={isMenuOpen}
                    onClose={toggleDrawer}
                    sx={{
                        "& .MuiPaper-root": {
                            backgroundColor: "background.default"
                        }
                    }}
                >
                    <CategoriesNav onClick={toggleDrawer} isMobile={isMobile}/>
                </Drawer>
            ) : (
                <CategoriesNav isMobile={isMobile}/>
            )}
        </Box>
    );
}
