import * as React from "react";
import {Layout} from "../Common/components/Layout";
import {useParams} from "react-router-dom";
import {Container, Grid} from "@mui/material";
import {CategoryThumbnail} from "./components/CategoryThumbnail";
import {CategoryThumbnailSkeleton} from "./components/CategoryThumbnailSkeleton";
import {TitleGradient} from "../Common/components/TitleGradient";
import {useArticles} from "../Common/domain/hooks/useArticles";
import {CATEGORIES} from "../Common/domain/helpers/categoriesHelpers";
import {Metas} from "../Common/components/Metas";
import {uiHelper} from "../Common/domain/helpers/uiHelper";

/**
 * La page avec la liste des vignettes par catégorie
 * Chaque page categorie à son propre thème
 * @constructor
 */
export function Category() {
    const {categoryId} = useParams();
    const [articles] = useArticles({categoryId});
    const categoryObject = CATEGORIES[uiHelper.getCategoryKeyFromCategoryId(categoryId)];

    return (
        <>
            <Metas
                title={categoryObject.label}
                description={`Les articles de la catégorie ${categoryObject.label}`}
            />
            <Layout themeOverride={categoryObject.theme}>
                <Container>
                    <TitleGradient component={"h1"} variant={"h2"} align={"left"}>
                        {categoryObject.label}
                    </TitleGradient>
                    <Grid container spacing={3} mt={1}>
                        {0 === articles.length ?
                            Array(9).fill("").map((_item, index) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                    <CategoryThumbnailSkeleton/>
                                </Grid>
                            ))
                            : 
                            articles.map((article) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={article.id}>
                                    <CategoryThumbnail article={article}/>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Container>
            </Layout>
        </>
    );
}

