import * as React from "react";
import {getNextArticle} from "../../../api/consumer";
import {Article} from "../../../api/interfaces/Article";

export const useNextArticle = (articleId: string, originalCreationNextArticle: Article): [Article] => {
    const [nextArticle, setNextArticle] = React.useState<Article>();    

    React.useEffect(() => {
        if (originalCreationNextArticle) {
            setNextArticle(originalCreationNextArticle);
        } else {
            getNextArticle(articleId)
                .then(article => {
                    setNextArticle(article);
                });
        }
    }, [articleId, originalCreationNextArticle]);

    return [nextArticle];
};
