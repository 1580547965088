import * as React from "react";
import {Box} from "@mui/material";
import {DatagoraSkeleton} from "./navigation/DatagoraSkeleton";
import {uiHelper} from "../domain/helpers/uiHelper";

type DatagoreImageProps = {src: string, alt: string; aspectRatio?: "1/1" | "16/9" | "12/5" | "4/3", borderRadius?: number}

export function DatagoraImage({src, alt, aspectRatio = "1/1", borderRadius = 2}: DatagoreImageProps) {
    if (!src) {
        return <DatagoraSkeleton width={"100%"} aspectRatio={aspectRatio}/>;
    }

    return (
        <Box 
            component={"picture"}
            sx={{
                display: "flex",
                width: "100%",
                paddingTop: uiHelper.getPaddingFromAspectRatio(aspectRatio),
                borderRadius: borderRadius,
                overflow: "hidden",
                position: "relative"
            }}
        >
            <img src={src} alt={alt} style={{height: "100%", objectFit: "cover", width: "100%", position: "absolute", top: 0, bottom: 0, left: 0, right: 0}}/>
        </Box>
    );
}
