import * as React from "react";
import {getHomepage, getOriginalCreations} from "../../../api/consumer";
import {Homepage} from "../../../api/interfaces/Homepage";
import {OriginalCreation} from "../../../api/interfaces/OriginalCreation";

export const useHomepageContent = (): [Homepage, OriginalCreation[]] => {
    const [homepageContent, setHomepageContent] = React.useState<{ homepageArticles: Homepage, originalCreations: OriginalCreation[] }>({homepageArticles: null, originalCreations: []});

    React.useEffect(() => {
        Promise.all([getHomepage(), getOriginalCreations()]).then(
            (values) => {
                setHomepageContent({homepageArticles: values[0], originalCreations: values[1]});
            });
    }, []);

    return [homepageContent.homepageArticles, homepageContent.originalCreations];
};
