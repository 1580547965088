import * as React from "react";
import {Typography, Box, Link, Hidden} from "@mui/material";
import {DatagoraSocialIcon} from "../../Common/components/DatagoraSocialIcon";
import {uiHelper} from "../../Common/domain/helpers/uiHelper";
import {Article} from "../../api/interfaces/Article";
import {Mail} from "react-feather";
import {useTheme} from "@mui/material/styles";

/**
 * Les liens de partage d'un article
 * @constructor
 */
export function ArticleShare({article}: {article: Article}) {
    const currentUrl = window.location.href;
    const theme = useTheme();

    return (
        <>
            <Box display={"flex"} alignItems={"center"}>
                <Hidden smDown>
                    <Typography variant={"caption"} mx={2} align={"center"} color={"text.secondary"}>
                        Partager
                    </Typography>
                </Hidden>
                <Link
                    href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
                    title={"Partager sur Facebook"}
                    target={"_blank"}
                    mr={2}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <DatagoraSocialIcon path={uiHelper.facebookIconPath}/>
                </Link>
                <Link
                    href={`https://twitter.com/share?url=${currentUrl}`}
                    title={"Partager sur Twitter"}
                    target={"_blank"}
                    mr={2}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <DatagoraSocialIcon path={uiHelper.twitterIconPath}/>
                </Link>
                <Link
                    href={`https://www.linkedin.com/share?url=${currentUrl}`}
                    title={"Partager sur Linkedin"}
                    target={"_blank"}
                    mr={2}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <DatagoraSocialIcon path={uiHelper.linkedinIconPath}/>
                </Link>
                <Link
                    href={`mailto:?subject=J'ai découvert cet article, il pourrait t'intéresser&body=${article.title}%0A${article.subTitle}%0A%0Ahttps://datagora.fr${uiHelper.getArticleLinkPath(article)}`}
                    title={"Partager par email"}
                    target={"_blank"}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <Mail color={theme.palette.grey[200]}/>
                </Link>
            </Box>
        </>
    );
}
