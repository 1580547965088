import {useLayoutEffect} from "react";
import {useLocation} from "react-router-dom";

export function DatagoraScrollToTop(): null {
    const {pathname} = useLocation();

    useLayoutEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [pathname]);

    return null;
}