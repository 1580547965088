import * as React from "react";
import {Link, Typography} from "@mui/material";
import {DatagoraImage} from "../../Common/components/DatagoraImage";
import {Link as RouterLink} from "react-router-dom";
import {OriginalCreation} from "../../api/interfaces/OriginalCreation";
import {getOriginalCreation} from "../../api/consumer";
import {uiHelper} from "../../Common/domain/helpers/uiHelper";

/**
 *  Carte section série exclusive de la HP
 * @constructor
 */
export function OriginalCreationThumbnail({originalCreation}: {originalCreation: OriginalCreation}) {
    const [originalCreationWithArticles, setOriginalCreationWithArticles] = React.useState<OriginalCreation | undefined>();
    
    React.useEffect(() => {
        getOriginalCreation(originalCreation.id)
            .then(response => {
                setOriginalCreationWithArticles(response);
            });
    }, []);

    if (undefined === originalCreation || undefined === originalCreationWithArticles) {
        return <></>;
    }

    return (
        <>
            <Link
                to={uiHelper.getArticleLinkPath(originalCreationWithArticles.articles[0])}
                component={RouterLink}
                sx={{
                    textDecoration: "none",
                    transition: "opacity .3s ease",
                    "&:hover": {
                        opacity: .8,
                    }
                }}
            >
                <DatagoraImage
                    src={originalCreation.homepageImageUrl}
                    alt={originalCreation.title}
                />
                <Typography variant={"h4"} component={"h2"} color={"text.primary"} pt={2} pb={1}>
                    {originalCreation.title}
                </Typography>
                {originalCreation.description && (
                    <Typography variant={"caption"} component={"h3"} color={"text.primary"}>
                        {originalCreation.description}
                    </Typography>
                )}
            </Link>
        </>
    );
}
