import * as React from "react";
import {Box} from "@mui/material";
import {DatagoraSkeleton} from "../../Common/components/navigation/DatagoraSkeleton";

/**
 * Le skeleton d'une vignette d'article
 * @constructor
 */
export function CategoryThumbnailSkeleton() {

    return (
        <>
            <DatagoraSkeleton aspectRatio={"1/1"} width={"100%"} height={"auto"}/>
            <Box mt={2}>
                <DatagoraSkeleton height={24} width={"90%"}/>
            </Box>
            <Box mt={1.5} mb={1.5}>
                <DatagoraSkeleton height={17} width={"70%"}/>
            </Box>
            <DatagoraSkeleton height={17} width={"45%"}/>
        </>
    );
}
