import * as React from "react";
import {Layout} from "../Common/components/Layout";
import {Button, Container, Typography, Link} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";

/**
 * La page 404
 * @constructor
 */
export function NotFound() {
    return (
        <Layout>
            <Container>
                <Typography variant={"h1"} textAlign={"center"} mb={4}>
                    Oops ! Quelque chose s'est mal passé
                </Typography>
                <Typography textAlign={"center"} mb={4}>
                    La page que vous recherchez n'existe pas.
                </Typography>
                <Link to={"/"} component={RouterLink} style={{display: "flex", justifyContent: "center"}}>
                    <Button>Revenir à l'accueil</Button>
                </Link>
            </Container>
        </Layout>
    );
}
