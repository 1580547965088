import {Article} from "./interfaces/Article";
import {OriginalCreation} from "./interfaces/OriginalCreation";
import {Homepage} from "./interfaces/Homepage";

const apiUrl = process.env.REACT_APP_API_URL;

const handleResponse = (response: Response): Promise<any> => {
    if (response.ok) {
        return response.json();
    } else {
        return Promise.reject("API Response not OK");
    }
};

export async function getArticles(categoryId?: string, pagination?: boolean): Promise<Article[]> {
    return fetch(`${apiUrl}/articles?pagination=${pagination ?? true}${categoryId ? "&category=" + categoryId : ""}`)
        .then((response) => {
            return handleResponse(response);
        })
        .then(json => {
            return Promise.resolve(json["hydra:member"]);
        });
}

export async function getArticle(id: string): Promise<Article> {
    return fetch(`${apiUrl}/articles/${id}`)
        .then((response) => {
            return handleResponse(response);
        })
        .then(json => {
            return Promise.resolve(json);
        });
}

export async function getNextArticle(id: string): Promise<Article | null> {
    return fetch(`${apiUrl}/articles/${id}/next`)
        .then((response) => {
            return handleResponse(response);
        })
        .then(json => {
            return Promise.resolve(json);
        });
}

export async function getOriginalCreations(): Promise<OriginalCreation[]> {
    return fetch(`${apiUrl}/original_creations`)
        .then((response) => {
            return handleResponse(response);
        })
        .then(json => {
            return Promise.resolve(json["hydra:member"]);
        });
}

export async function getOriginalCreation(id: string): Promise<OriginalCreation> {
    return fetch(`${apiUrl}/original_creations/${id.replace("/api/original_creations/", "")}`)
        .then((response) => {
            return handleResponse(response);
        })
        .then(json => {
            return Promise.resolve(json);
        });
}

export function getHomepage(): Promise<Homepage> {
    return fetch(`${apiUrl}/homepage`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return Promise.reject("API Response not OK");
            }
        })
        .then(json => {
            return Promise.resolve(json);
        });
}
