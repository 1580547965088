import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {
    Routes,
    Route,
    HashRouter,
} from "react-router-dom";
import {DatagoraScrollToTop} from "./Common/components/DatagoraScrollToTop";
import {Home} from "./Home/Home";
import {Category} from "./Category/Category";
import {Article} from "./Article/Article";
import {OriginalCreation} from "./OriginalCreation/OriginalCreation";
import {Contact} from "./Contact/Contact";
import {NotFound} from "./NotFound/NotFound";
import {Team} from "./Team/Team";
import {Legals} from "./Legals/Legals";
import {Charte} from "./Charte/Charte";
import "./assets/styles/styles.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <HashRouter>
            <DatagoraScrollToTop/>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route index element={<Home />} />
                    <Route path={"categorie"}>
                        <Route path={":categoryId"} element={<Category />} />
                        <Route path={":categoryId/:articleId"} element={<Article />} />
                    </Route>
                    <Route path={"series-exclusives"}>
                        <Route path={":originalCreationId/:categoryId"} element={<OriginalCreation />} />
                        <Route path={":originalCreationId/:categoryId/:articleId"} element={<OriginalCreation />} />
                    </Route>
                    <Route path="contact" element={<Contact />} />
                    <Route path="equipe" element={<Team />} />
                    <Route path="mentions-legales" element={<Legals />} />
                    <Route path="charte" element={<Charte />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </HashRouter>
    </React.StrictMode>
);
