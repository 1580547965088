import * as React from "react";
import {Layout} from "../Common/components/Layout";
import {Box, Button, Grid, Theme, ThemeProvider, Typography} from "@mui/material";
import {Container} from "@mui/material";
import {Feather, Flag, Headphones, Mic, TrendingUp} from "react-feather";
import {ColorsByCategory} from "./components/ColorsByCategory";
import {
    categoryEconomyTheme,
    categoryEnvironmentTheme,
    categoryPoliticTheme,
    categoryInternationalTheme,
    categorySocietyTheme, theme as defaultTheme
} from "../Common/theme/theme";


function Typos() {
    return (<Box display={"flex"} flexDirection={"column"} gap={4}>
        <Typography variant={"h3"}>
            Typography
        </Typography>
        <Typography variant="h1">
            <Grid container>
                <Grid item xs={4}>
                    Titre 1
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Roboto Bold 64pt
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Line height: 75px
                    </Typography>
                </Grid>
            </Grid>
        </Typography>
        <Typography variant="h2">
            <Grid container>
                <Grid item xs={4}>
                    Titre 2
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Roboto Bold 48pt
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Line height: 56px
                    </Typography>
                </Grid>
            </Grid>
        </Typography>
        <Typography variant="h3">
            <Grid container>
                <Grid item xs={4}>
                    Titre 3
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Roboto Bold 34pt
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Line height: 40px
                    </Typography>
                </Grid>
            </Grid>
        </Typography>
        <Typography variant="h4">
            <Grid container>
                <Grid item xs={4}>
                    Titre 4
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Roboto Bold 22pt
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Line height: 26px
                    </Typography>
                </Grid>
            </Grid>
        </Typography>
        <Typography variant="h5">
            <Grid container>
                <Grid item xs={4}>
                    Titre 5
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Roboto Bold 16pt
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Line height: 19px
                    </Typography>
                </Grid>
            </Grid>
        </Typography>
        <Typography variant="h6">
            <Grid container>
                <Grid item xs={4}>
                    Titre 6
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Roboto Bold 16pt
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Line height: 24px
                    </Typography>
                </Grid>
            </Grid>
        </Typography>
        <Typography variant="subtitle1">
            <Grid container>
                <Grid item xs={4}>
                    sous-titre 1
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Roboto Regular 14pt
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Line height: 16px
                        UPPERCASE
                    </Typography>
                </Grid>
            </Grid>
        </Typography>
        <Typography variant="subtitle2">
            <Grid container>
                <Grid item xs={4}>
                    sous-titre 2
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Roboto Regular 12pt
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Line height: 14px
                        UPPERCASE
                    </Typography>
                </Grid>
            </Grid>
        </Typography>
        <Typography variant="body1">
            <Grid container>
                <Grid item xs={4}>
                    Texte 1
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Roboto Regular 18pt
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Line height: 28px
                    </Typography>
                </Grid>
            </Grid>
        </Typography>
        <Typography variant="body2">
            <Grid container>
                <Grid item xs={4}>
                    Texte 2
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Roboto Regular 16pt
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Line height: 24px
                    </Typography>
                </Grid>
            </Grid>
        </Typography>
        <Typography variant="caption">
            <Grid container>
                <Grid item xs={4}>
                    Caption
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Roboto Regular 14pt
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"caption"} color={"#AAAAAA"}>
                        Line height: 20px
                    </Typography>
                </Grid>
            </Grid>
        </Typography>
        {/*<Typography variant="body3">
            Texte 3
        </Typography>*/}
    </Box>);
}

const DemoButtons = ({label, theme = defaultTheme}: {label: string; theme?: Theme}) => {
    return (
        <ThemeProvider theme={theme}>
            <>
                <Box>
                    <Typography>{label}</Typography>
                </Box>
                <Box display={"flex"} gap={2}>
                    <Button
                        variant="contained"
                    >
                        Button primary
                    </Button>
                    <Button
                        variant="contained"
                        color={"secondary"}

                    >
                        Button secondary
                    </Button>
                </Box>
            </>
        </ThemeProvider>
    );
};

function CallToAction() {
    return (
        <Box>
            <Typography variant="h3" py={5}>Call to action</Typography>
            <Box
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: 32,
                }}>
                <Box>
                    <DemoButtons
                        label={"Avec theme default"}
                    />
                </Box>
                <Box>
                    <DemoButtons
                        label={"Avec theme Environment"}
                        theme={categoryEnvironmentTheme}
                    />
                </Box>
                <Box>
                    <DemoButtons
                        label={"Avec theme Economie"}
                        theme={categoryEconomyTheme}
                    />
                </Box>
                <Box>
                    <DemoButtons
                        label={"Avec theme Politique"}
                        theme={categoryPoliticTheme}
                    />
                </Box>
                <Box>
                    <DemoButtons
                        label={"Avec theme Société"}
                        theme={categorySocietyTheme}
                    />
                </Box>
                <Box>
                    <DemoButtons
                        label={"Avec theme International"}
                        theme={categoryInternationalTheme}
                    />
                </Box>
            </Box>
        </Box>
    );
}

function Colors() {
    return (
        <>
            <Typography variant="h3" pb={4}>Colors</Typography>
            <Grid container gap={4} pb={4}>
                <Grid item xs={2}>
                    <Box>
                        <Box component={"span"}
                            sx={{
                                backgroundColor: "common.black",
                                borderRadius: "8px",
                                display: "block",
                                height: 80,
                                width: 80,
                            }}>
                        </Box>
                        <Typography variant={"body2"} pt={1}>#333333</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box component={"span"}
                        sx={{
                            backgroundColor: "grey.200",
                            borderRadius: "8px",
                            display: "block",
                            height: 80,
                            width: 80,
                        }}>
                    </Box>
                    <Typography variant={"body2"} pt={1}>#AAAAAA</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Box component={"span"}
                        sx={{
                            backgroundColor: "grey.100",
                            borderRadius: "8px",
                            display: "block",
                            height: 80,
                            width: 80,
                        }}>
                    </Box>
                    <Typography variant={"body2"} pt={1}>#E0E0E0</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Box component={"span"}
                        style={{
                            backgroundColor: "common.white",
                            border: "1px solid #E0E0E0",
                            borderRadius: 8,
                            display: "block",
                            height: 80,
                            width: 80,
                        }}>
                    </Box>
                    <Typography variant={"body2"} pt={1}>#FFFFFF</Typography>
                </Grid>
            </Grid>
        </>
    );
}

function Datagora() {
    return (
        <>
            <Typography variant="h4" pb={2}>Datagora</Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} gap={2}>
                    <Box pb={2} >
                        <span style={{
                            boxSizing: "border-box",
                            background: "linear-gradient(90deg, rgba(29,97,255,1) 0%, rgba(103,58,171,1) 50%, rgba(255,46,96,1) 100%)",
                            borderRadius: "8px",
                            color: "#FFF",
                            maxWidth: "304px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: "20px 8px",
                            alignItems: "center",
                        }}>
                            <Typography variant={"caption"}>#1D61FF</Typography>
                            <Typography variant={"caption"}>#673AAB</Typography>
                            <Typography variant={"caption"}>#FF2E60</Typography>
                        </span>
                    </Box>
                    <span style={{
                        boxSizing: "border-box",
                        background: "#FBFFF8",
                        border: "1px solid #E0E0E0",
                        borderRadius: "8px",
                        color: "#000",
                        height: 32,
                        maxWidth: "304px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "6px 8px",
                        alignItems: "center",
                    }}>
                        <Typography variant={"caption"}>Background</Typography>
                        <Typography variant={"caption"}>#FBFFF8</Typography>
                    </span>
                </Grid>
            </Grid> 
        </>
    ); 
}

/**
 *  couleurs des différents thèmes articles
 * @constructor
 */
/*function ColorsThemes() {
    const theme = useTheme();
    
    return (
        <>
            <Box py={4}>
                <Typography variant={"h3"}>Theme Dark</Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Box display={"flex"} gap={2}>
                    <Box
                        bgcolor={"primary.main"}
                        sx={{
                            borderRadius: "8px",
                            display: "block",
                            height: 64,
                            width: 64,
                        }}>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"} flexDirection={"column"}>
                        <Typography variant={"h5"} pt={1}>Accent</Typography>
                        <Typography variant={"body2"} color={"grey.200"} pt={1}>{theme.palette.primary.main}</Typography>
                    </Box>
                </Box>

                <Box display={"flex"} gap={2}>
                    <Box
                        bgcolor={"background.default"}
                        sx={{
                            borderRadius: "8px",
                            display: "block",
                            height: 64,
                            width: 64,
                        }}>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"} flexDirection={"column"}>
                        <Typography variant={"h5"} pt={1}>Background</Typography>
                        <Typography variant={"body2"} color={"grey.200"} pt={1}>#141E33</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
}*/

/**
 * thèmes articles
 * @constructor
 */

/*function ThemesStyles() {
    return  (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Box
                        bgcolor={"background.default"}
                        sx={{
                            borderRadius: 2,
                            padding: "64px 112px"
                        }}
                    >
                        <Box textAlign={"center"}>
                            <Typography variant={"h2"} color={"primary.main"}>
                                Lorem ipsum dolor sit amet
                            </Typography>
                            <Typography variant={"h4"} color={theme.palette.text.primary} pt={2}>
                                Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.
                            </Typography>
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"} pt={4}>
                            <Box>
                                <Typography variant={"caption"} color={"text.secondary"} paddingRight={4}>Source</Typography>
                                <Typography variant={"caption"} color={"#AAA"} paddingRight={8}>Date de publication</Typography>
                            </Box>
                            <Typography variant={"caption"} color={"#AAA"}>Partager</Typography>
                            <Box>
                                <Box display={"flex"} gap={2}>
                                    <Typography variant={"caption"} color={"#AAA"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#AAA"><path d="M24 12.07C24 5.41 18.63 0 12 0S0 5.4 0 12.07C0 18.1 4.39 23.1 10.13 24v-8.44H7.08v-3.49h3.04V9.41c0-3.02 1.8-4.7 4.54-4.7 1.31 0 2.68.24 2.68.24v2.97h-1.5c-1.5 0-1.96.93-1.96 1.89v2.26h3.32l-.53 3.5h-2.8V24C19.62 23.1 24 18.1 24 12.07"/></svg>
                                    </Typography>
                                    <Typography variant={"caption"} color={"#AAA"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#AAA"><path d="M24 4.37a9.6 9.6 0 0 1-2.83.8 5.04 5.04 0 0 0 2.17-2.8c-.95.58-2 1-3.13 1.22A4.86 4.86 0 0 0 16.61 2a4.99 4.99 0 0 0-4.79 6.2A13.87 13.87 0 0 1 1.67 2.92 5.12 5.12 0 0 0 3.2 9.67a4.82 4.82 0 0 1-2.23-.64v.07c0 2.44 1.7 4.48 3.95 4.95a4.84 4.84 0 0 1-2.22.08c.63 2.01 2.45 3.47 4.6 3.51A9.72 9.72 0 0 1 0 19.74 13.68 13.68 0 0 0 7.55 22c9.06 0 14-7.7 14-14.37v-.65c.96-.71 1.79-1.6 2.45-2.61z"/></svg>
                                    </Typography>
                                    <Typography variant={"caption"} color={"#AAA"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#AAA"><path d="M22.23 0H1.77C.8 0 0 .77 0 1.72v20.56C0 23.23.8 24 1.77 24h20.46c.98 0 1.77-.77 1.77-1.72V1.72C24 .77 23.2 0 22.23 0zM7.27 20.1H3.65V9.24h3.62V20.1zM5.47 7.76h-.03c-1.22 0-2-.83-2-1.87 0-1.06.8-1.87 2.05-1.87 1.24 0 2 .8 2.02 1.87 0 1.04-.78 1.87-2.05 1.87zM20.34 20.1h-3.63v-5.8c0-1.45-.52-2.45-1.83-2.45-1 0-1.6.67-1.87 1.32-.1.23-.11.55-.11.88v6.05H9.28s.05-9.82 0-10.84h3.63v1.54a3.6 3.6 0 0 1 3.26-1.8c2.39 0 4.18 1.56 4.18 4.89v6.21z"/></svg>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}*/

/*const demoArticle = {
    id: "string",
    title: "Demo title",
    subTitle: "Demo title",
    category: "Demo title",
    publicationDate: "2022-07-29T00:00:00+00:00",
    origin: "string",
    introduction: "string",
    content: "string",
    report: "string",
    source: "string",
    backgroundColor: "string",
    accentuationColor: "string",
    theme: "string",
    originalCreation: "string",
};*/


/**
 * La page Charte graphique
 * Override theme général
 * @constructor
 */
export function Charte(): React.ReactElement {

    return (
        <Layout>
            <Container>
                <Typography variant="h1" align={"center"} py={4}>Charte graphique</Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} lg={6}>
                        <Box pb={8}>
                            <Typos/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Colors/>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={6}>
                                <Box pb={4}>
                                    <Datagora/>
                                </Box>
                                <Box pb={4}>
                                    <ColorsByCategory
                                        title={"Economie"}
                                        primaryColor={"#FF2E48"}
                                        secondaryColor={"#C03588"}
                                        backgroundColor={"#FFFAFB"}
                                        icon={<TrendingUp color="inherit" size={24}/>}
                                    />
                                </Box>
                                <Box pb={4}>
                                    <ColorsByCategory
                                        title={"Société"}
                                        primaryColor={"#FA9917"}
                                        backgroundColor={"#FFFBF6"}
                                        secondaryColor={"#FF2E48"}
                                        icon={<Headphones color="inherit" size={24}/>}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Box pb={4}>
                                    <ColorsByCategory
                                        title={"Environnement"}
                                        primaryColor={"#62C92A"}
                                        backgroundColor={"#FBFFF8"}
                                        secondaryColor={"#0084FF"}
                                        icon={<Feather color="inherit" size={24}/>}
                                    />
                                </Box>
                                <Box pb={4}>
                                    <ColorsByCategory
                                        title={"Politique"}
                                        primaryColor={"#0084FF"}
                                        backgroundColor={"#F7F9FB"}
                                        secondaryColor={"#C03588"}
                                        icon={<Mic color="inherit" size={24}/>}
                                    />
                                </Box>
                                <Box pb={4}>
                                    <ColorsByCategory
                                        title={"International"}
                                        primaryColor={"#C03588"}
                                        secondaryColor={"#FA9917"}
                                        backgroundColor={"#FFF8FC"}
                                        icon={<Flag color="inherit" size={24}/>}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <CallToAction/>
                    </Grid>
                </Grid>
                <Box py={4}>
                    <Typography variant={"h3"}>Cards</Typography>
                </Box>
                {/* <ThemeProvider theme={defaultTheme}>
                    <Box py={5}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={2}>
                                <ColorsThemes/>
                            </Grid>
                            <Grid item xs={12} lg={10}>
                                <ThemesStyles/>
                            </Grid>
                        </Grid>
                    </Box>
                </ThemeProvider> */}
            </Container>
        </Layout>
    );
}


