import * as React from "react";
import {getArticle} from "../../../api/consumer";
import {Article} from "../../../api/interfaces/Article";

export const useArticle = ({articleId}: {articleId: string}): [Article] => {
    const [article, setArticle] = React.useState<Article>();

    React.useEffect(() => {
        getArticle(articleId)
            .then(article => {
                setArticle(article);
            });
    }, [articleId]);

    return [article];
};
