export default class Axeptio {
    private static sdk: Promise<Record<string, unknown>>;

    public static loadSdk(): Promise<Record<string, unknown>> {
        if ("object" !== typeof this.sdk) {
            this.sdk = new Promise((resolve) => {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window._axcb = window._axcb || [];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window._axcb.push((sdk) => {
                    sdk.on("consent:saved", () => {
                        window.location.reload();
                    });
                    this.initChoices(sdk);
                    resolve(sdk);
                });

                this.loadScript();
            });
        }

        return this.sdk;
    }

    private static initChoices(sdk): void {
        sdk.on("cookies:complete", (choices: Record<string, unknown>) => {
            const event = new CustomEvent<Record<string, unknown>>("axeptio:choices", {detail: choices});
            localStorage.setItem("axeption_vimeo", choices.vimeo);
            document.dispatchEvent(event);
        });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private static loadScript(): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.axeptioSettings = {
            clientId: process.env.REACT_APP_AXEPTIO_CLIENT_ID,
        };

        const el = document.createElement("script");
        el.setAttribute("src", "https://static.axept.io/sdk.js");
        el.setAttribute("type", "text/javascript");
        el.setAttribute("async", "true");
        if (null !== document.body) {
            document.body.appendChild(el);
        }
    }
}
