import * as React from "react";
import {Box, Container, Typography, Link} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {FooterLogo} from "./navigation/FooterLogo";
// @ts-ignore
import logoPurjus from "../../assets/images/logo-purjus.png";

/**
 * Le footer commun aux pages
 * @constructor
 */
export function Footer(): React.ReactElement {

    return (
        <Box component={"footer"} bgcolor={"background.default"}>
            <Box
                sx={(theme) => ({
                    backgroundColor: theme.palette.background.paper,
                    borderTop: `1px solid ${theme.palette.divider}`
                })}
            >
                <Container>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        flexWrap={"wrap"}
                        py={1.75}
                    >
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            sx={{
                                minWidth: {
                                    xs: "80%",
                                    md: "auto"
                                },
                                flex: {
                                    md: 1
                                }
                            }}
                        >
                            <Typography variant={"caption"}>Datagora {new Date().getFullYear()}</Typography>
                            <Typography variant={"caption"} color={"text.secondary"} mx={1}>•</Typography>
                            <Link to={"/mentions-legales"} component={RouterLink} underline="none">
                                <Typography variant={"caption"} color={"text.secondary"}>Mentions Légales</Typography>
                            </Link>
                        </Box>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            sx={{
                                justifyContent: {
                                    xs: "flex-end",
                                    md: "center"
                                },
                                flex: {
                                    md: 1
                                }
                            }}
                        >
                            <Link to={"/"} component={RouterLink} style={{display: "flex"}} aria-label={"Logo de Datagora media"}>
                                <FooterLogo/>
                            </Link>
                        </Box>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            sx={{
                                justifyContent: {
                                    xs: "flex-start",
                                    md: "flex-end"
                                },
                                flex: {
                                    md: 1
                                }
                            }}
                        >
                            <Typography variant={"caption"} color={"text.secondary"} mr={1}>conception</Typography>
                            <Link
                                href={"https://purjus.fr/"}
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                                title={"Accéder au site de Purjus communication"}
                                style={{display: "flex"}}
                            >
                                <img src={logoPurjus} alt={"logo purjus communication"} width={56} height={12}/>
                            </Link>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}
