import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import {StepConnector} from "@mui/material";
import {theme} from "../../Common/theme/theme";
import {Article} from "../../api/interfaces/Article";
import {useEffect} from "react";

export const OriginalCreationMenuStepper = React.forwardRef(({articles, onClick, currentArticleIndex}: {articles: Article[], onClick: (i: number) => void, currentArticleIndex: number}, ref) => {
    const [activeStep, setActiveStep] = React.useState(currentArticleIndex);

    useEffect(() => {
        setActiveStep(currentArticleIndex);
    }, [currentArticleIndex]);

    return (
        <Stepper
            activeStep={activeStep}
            orientation="vertical"
            connector={<StepConnector/>}
            sx={{
                position: "relative",
                zIndex: 1,
                "& .MuiStepConnector-line" : {
                    borderColor: "transparent",
                    minHeight: 32
                }
            }}
        >
            {articles.map((article, index) => (
                <Step key={index} sx={{
                    position: "relative",
                    "&.Mui-completed .MuiStepLabel-root:before": {
                        opacity: 1
                    },
                }}>
                    <StepLabel
                        StepIconComponent={() => <div style={{
                            backgroundColor: theme.palette.common.white,
                            height: 12,
                            width: 12,
                            borderRadius: "50%",
                            position: "relative",
                            marginRight: 16,
                        }}/>}
                        sx={(theme) => ({
                            position: "relative",
                            alignItems: "center",
                            padding: 0,
                            "&:before" : {
                                content: "''",
                                background: theme.palette.primary.main,
                                width: 16,
                                height: "calc(100% + 44px)",
                                position: "absolute",
                                left: -2,
                                top: -72,
                                opacity: activeStep === index ? 1 : 0,
                            },
                            "&:after" : {
                                content: "''",
                                background: theme.palette.primary.main,
                                width: 32,
                                height: 32,
                                borderRadius: "50%",
                                position: "absolute",
                                left: -10,
                                top: "50%",
                                transform: "translateY(-50%)",
                                display: activeStep === index ? "block" : "none",
                            }
                        })}
                        defaultValue={30}
                        ref={index === articles.length - 1 ? ref : null}
                        onClick={() => {
                            onClick(index);
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={(theme) => ({
                                fontWeight: 700,
                                color: activeStep === index ? theme.palette.text.primary : theme.palette.text.secondary,
                                "&:not(:last-child)": {
                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                    paddingBottom: theme.spacing(2),
                                }
                            })}
                        >
                            {article.title}
                        </Typography>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
});
