import {createTheme} from "@mui/material";
import {uiHelper} from "../domain/helpers/uiHelper";

export let theme = createTheme({
    palette: {
        primary: {
            main: "#FF2E48",
            light: uiHelper.getLightenColor("#FF2E48")
        },
        secondary: {
            main: "#0084FF",
            light: uiHelper.getLightenColor("#0084FF")
        },
        grey: {
            100: "#E0E0E0",
            200: "#AAAAAA",
        },
        background: {
            default: "#F7F9FB",
            paper: "#FFF",
        },
        text: {
            primary: "#333333",
            secondary: "#AAAAAA"
        },
        divider: "#E0E0E0"
    },
    components: {
        MuiButton: {
            defaultProps: {
                color: "primary",
                disableElevation: true,
                variant: "contained"
            },
            styleOverrides: {
                root: ({theme}) => ({
                    borderRadius: "20px",
                    color: theme.palette.common.white,
                    maxWidth: "100%",
                    padding: "12px 16px",
                    textTransform: "none",
                    fontSize: 14,
                    lineHeight: "16px",
                    whiteSpace: "nowrap",
                    letterSpacing: "normal",
                }),
                containedPrimary: ({theme}) => ({
                    backgroundColor: theme.palette.primary.main,
                    "&:hover": {
                        backgroundColor: theme.palette.primary.light,
                    },
                }),
                containedSecondary: ({theme}) => ({
                    backgroundColor: theme.palette.secondary.main,
                    "&:hover": {
                        backgroundColor: theme.palette.secondary.light,
                    },
                }),
                text: ({theme}) => ({
                    backgroundColor: "transparent",
                    color: theme.palette.common.black,
                    border: `1px solid ${theme.palette.grey[100]}`,
                }),
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: ({theme}) => ({
                    [theme.breakpoints.up("lg")]: {
                        maxWidth: 1488,
                    },
                    "@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1024px)": {
                        maxWidth: 1024,
                    },
                }),
                maxWidthMd: ({theme}) => ({
                    [theme.breakpoints.up("md")]: {
                        maxWidth: 1088,
                    }
                }),
                maxWidthSm: ({theme}) => ({
                    [theme.breakpoints.up("sm")]: {
                        maxWidth: 688,
                    },
                    "@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1024px)": {
                        maxWidth: 600,
                    }
                }),
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    cursor: "pointer",
                    "&.Mui-disabled": {
                        cursor: "pointer"
                    },
                },
                iconContainer: () => ({
                    position: "relative",
                    zIndex: 1
                })
            },
        },
        MuiStepContent: {
            styleOverrides: {
                root: {
                    borderLeft: "16px solid #E81A5B",
                    height: "100%",
                    marginLeft: 4,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: ({theme}) =>  ({
                    color: theme.palette.text.primary
                })
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: ({theme}) =>  ({
                    borderColor: theme.palette.grey[100]
                })
            }
        }
    },
});

theme = createTheme(theme, {
    typography: {
        fontFamily: "'Roboto', 'sans-serif'",
        h1: {
            fontSize: 48,
            lineHeight: "56px",
            fontWeight: 700,
            letterSpacing: "normal",
        },
        h2: {
            fontSize: 34,
            lineHeight: "40px",
            fontWeight: 700,
            letterSpacing: "normal",
            [theme.breakpoints.up("sm")]: {
                fontSize: 48,
                lineHeight: "56px",
            }
        },
        h3: {
            fontWeight: 700,
            fontSize: 28,
            lineHeight: "33px",
            letterSpacing: "normal",
            [theme.breakpoints.up("sm")]: {
                fontSize: 34,
                lineHeight: "40px",
            }
        },
        h4: {
            fontWeight: 700,
            fontSize: 18,
            lineHeight: "28px",
            letterSpacing: "normal",
            [theme.breakpoints.up("sm")]: {
                fontSize: 22,
                lineHeight: "26px",
            }
        },
        h5: {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: "19px",
            letterSpacing: "normal",
        },
        h6: {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: "24px",
            letterSpacing: "normal",
        },
        subtitle1: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: "16px",
            textTransform: "uppercase",
            letterSpacing: "normal",
        },
        subtitle2: {
            fontWeight: 500,
            fontSize: 12,
            lineHeight: "14px",
            textTransform: "uppercase",
            letterSpacing: "normal",
        },
        body1: {
            fontWeight: 400,
            fontSize: 18,
            lineHeight: "28px",
            textTransform: "none",
            letterSpacing: "normal",
        },
        body2: {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "24px",
            textTransform: "none",
            letterSpacing: "normal",
        },
        caption: {
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "20px",
            textTransform: "none",
            letterSpacing: "normal"
        },
    },
});

export const categoryEnvironmentTheme = createTheme(theme, {
    palette: {
        primary: {
            main: "#62C92A",
            light: uiHelper.getLightenColor("#62C92A")
        },
        secondary: {
            main: "#0084FF",
            light: uiHelper.getLightenColor("#0084FF")
        },
        background: {
            default: "#FBFFF8",
        }
    },
});

export const categoryEconomyTheme = createTheme(theme, {
    palette: {
        primary: {
            main: "#FF2E48",
            light: uiHelper.getLightenColor("#FF2E48")
        },
        secondary: {
            main: "#C03588",
            light: uiHelper.getLightenColor("#C03588")
        },
        background: {
            default: "#FFFAFB",
        },
    },
});

export const categoryPoliticTheme = createTheme(theme, {
    palette: {
        primary: {
            main: "#0084FF",
            light: uiHelper.getLightenColor("#0084FF")
        },
        secondary: {
            main: "#C03588",
            light: uiHelper.getLightenColor("#C03588")
        },
        background: {
            default: "#F7F9FB",
        },
    },
});

export const categorySocietyTheme = createTheme(theme, {
    palette: {
        primary: {
            main: "#FA9917",
            light: uiHelper.getLightenColor("#FA9917")
        },
        secondary: {
            main: "#FF2E48",
            light: uiHelper.getLightenColor("#FF2E48")
        },
        background: {
            default: "#FFFBF6",
        },
    },
});

export const categoryInternationalTheme = createTheme(theme, {
    palette: {
        primary: {
            main: "#C03588",
            light: uiHelper.getLightenColor("#C03588")
        },
        secondary: {
            main: "#FA9917",
            light: uiHelper.getLightenColor("#FA9917")
        },
        background: {
            default: "#FFF8FC",
        },
    },
});
