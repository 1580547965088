import * as React from "react";
import {Box} from "@mui/material";

/**
 * La ligne en dégradé du header
 * @constructorF
 */
export function HeaderGradientLine(): React.ReactElement {
    return (
        <Box
            sx={(theme) => ({
                background: `linear-gradient(95.19deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                height: 8
            })}
        ></Box>
    );
}
